import { abtservice } from 'public/src/services/abt'
import { getQueryString } from '@shein/common-function'
// eslint-disable-next-line @shein-aidc/abt/abt
const AB_TEST = () => abtservice.getUserAbtResultForAnalysis({ posKeys: 'SearchDefaultNew,SearchHotGD,SearchHotNew,SearchSuggestwordFeedback' }).sa

// 原词超过150即截取前150位
function sliceWord(word, type = 2) {
  return type === 2 ? (word ?? '').slice(0, 150) : word
}

// 埋点要求的字段名是entrancetype（小写）, url携带的entranceType（大写）
function getEntrancetypeVal() {
  return window.SaPageInfo?.page_param?.entrancetype || getQueryString({ key: 'entranceType' }) || '-'
}

/**
 * 搜索埋点玩法
 *1 expose_search  落地曝光, 上报搜索框(内)内容 --e.g.原词/默认词全组
 *2 click_search  点击搜索框, 上报搜索框(内)内容 --e.g.原词/默认词当前轮更词
 *3 expose_top_site_search  抽屉内容曝光
 *4 click_top_site_search  抽屉内容点击 --- 修改dosearch
 */
const analysis = {
  buildResultContent({ currentWord, correction, placeholder, defaultWords, historyWords = [], hotImages = [], hotWords = [], associationWords = { word: [] }, localIndex }) {
    return Array.prototype.concat(
      currentWord
        ? `2\`${sliceWord(currentWord)}\`1\`-\`-\`-\`common\`-\`-`
        : (defaultWords || (placeholder ? [placeholder] : [])).filter(i => !!i.word).map((_, _i) => `3\`${_.word}\`${(localIndex + 1) || (_i + 1)}\`${_.type || '-'}\`-\`${_.wordLabel?.labelType || '-'}\`${_?.wordTagType || 'common'}\`${_.word_id || '-'}\`-`),
      historyWords.map((_, _i) => `5\`${_.keywords}\`${_i + 1}\`-\`-\`-\`${_?.wordTagType || 'common'}\`-\`-`),
      hotImages.map((_, _i) => `${_.from === 'ccc' ? 9 : 6}\`${_.word}\`${_i + 1}\`${_.type || '-'}\`-\`${_.wordLabel?.labelType || '-'}\`${_?.wordTagType || 'common'}\`${_?.word_id || '-'}\`-`),
      hotWords.map((_, _i) => `${_.from === 'ccc' ? 9 : 6}\`${_.word}\`${hotImages.length + _i + 1}\`${_.type || '-'}\`-\`${_.wordLabel?.labelType || '-'}\`${_?.wordTagType || 'common'}\`${_.word_id || '-'}\`-`),
      correction ? `8\`${correction}\`1\`-\`-\`-\`common\`-\`-` : [],
      associationWords.word.map((_, _i) => `${correction ? 8 : 4}\`${_.keywords}\`${(correction ? 1 : 0) + _i + 1}\`${'-'}\`${'-'}\`${_.tag || '-'}\`${_?.wordTagType || 'common'}\`${_.word_id || '-'}\`${_.imgUrl ? 1 : 0 }`),
    ).join()
  },
  buildIdentifier({ currentWord, correction, placeholder, defaultWords, historyWords = [], hotImages = [], hotWords = [], associationWords = { word: [] } }) {
    // {st: 2,  // 类型 sc: '', // 原词 sr: 0, // 纠错词 ps: 0 // 位置 }
    const arr = []
    currentWord
      ? arr.push({ st: 2, sc: sliceWord(currentWord), sr: 0, ps: 1  })
      : (defaultWords || (placeholder ? [placeholder] : [])).filter(i => !!i.word).forEach((_, _i) => {
        `3\`${_.word}\`${_i + 1}\`${_.type || ''}`
        arr.push({ st: 3, sc: _.word, ps: _i + 1, sr: 0 })
      })
    historyWords.forEach((_, _i) => arr.push({ st: 5, sc: _.keywords, ps: _i + 1, sr: 0 }))
    hotImages.forEach((_, _i) => arr.push({ st: 9, sc: _.word, ps: _i + 1, sr: 0 }))
    hotWords.forEach((_, _i) => arr.push({ st: 6, sc: _.word, ps: _i + 1, sr: 0 }))
    correction && arr.push({ st: 8, sc: currentWord, ps: 1, sr: correction })
    associationWords.word.forEach((_, _i) => arr.push({ st: correction ? 8 : 4, sc: _.keywords, ps: (correction ? 1 : 0) + _i + 1, sr: 0 }))
    return arr.map(({ st, sc, sr, ps }) => `st=${st}\`sc=${sc}\`sr=${sr}\`ps=${ps}`).join(',')
  },
  searchInput(activity_name, payload, options) {
    const trace_id = (payload.associationWords?.word ||
      payload.hotWords ||
      payload.defaultWords ||
      (payload.placeholder && [payload.placeholder]) || [])[0]?.trace_id || '-'
    const data = {
      activity_name,
      activity_param: {
        trace_id,
        search_content: sliceWord(payload.currentWord) || payload.placeholder?.word || '',
        result_content: this.buildResultContent(payload),
        abtest: AB_TEST(),
        crowd_id: payload.crowdId || payload.placeholder?.crowdId || 0,
        src_module: 'search',
        src_identifier: this.buildIdentifier(payload),
        ...options
      }
    }
    if(['expose_search', 'click_search', 'expose_top_site_search', 'click_top_site_search'].includes(activity_name)) {
      data.activity_param && (data.activity_param.entrancetype = getEntrancetypeVal())
      window.argResultContent = data?.activity_param?.result_content || '-'
    }
    sa('send', data)
  },
  doSearch({ keyword = '', result_type = 2, data: { trace_id, word_id, position = 1, type, tag = '' }, crowdId, originWord, wordTagType = 'common', wordExtraReport, reportExtendData }) {
    if (!keyword || !result_type) throw new Error('search click analysis error')
    const isAssocionWords = [4, 8].includes(result_type) 
    const isOriginWordExtend = reportExtendData?.type === 'originWordIdAndAssociateTraceId' && result_type === 2
    const data = {
      activity_name: 'click_top_site_search',
      activity_param: {
        trace_id: isOriginWordExtend && (reportExtendData.inputWord === keyword) ? reportExtendData.associationTraceId || '-' : trace_id || '-',
        search_content: sliceWord(keyword, result_type),
        result_content: `${result_type}\`${sliceWord(keyword, result_type)}\`${position}\`${type || '-'}\`${'-'}\`${tag || '-'}\`${wordTagType}\`${isOriginWordExtend ? (reportExtendData?.inputWord === keyword ? reportExtendData.inputWordId || '-' : '-') : (word_id || '-')}\`${isAssocionWords ? wordExtraReport?.hasImg || 0 : '-'}`,
        abtest: AB_TEST(),
        crowd_id: crowdId || 0,
        src_module: 'search',
        entrancetype: getEntrancetypeVal(),
        src_identifier: `st=${result_type}\`sc=${sliceWord(originWord, result_type)}\`sr=${result_type == 8 ? keyword : '0'}\`ps=${position}`,
      }
    }
    window.argResultContent = data?.activity_param?.result_content || '-'
    sa('send', data, { beacon: 1 })
  },
  clickDelete() {
    sa('send', {
      activity_name: 'click_search_clear_history'
    })
  }
}
export default analysis
