const state = {
  unReadNum: 0,
  isMsgActivityAndPromoOnly: false,
  couponOrPointsData: {
    pointsNum: 0,
    couponNum: 0,
    isReqData: false,
    isClickCoupon: false,
    isClickPoints: false,
    isOpenCoupon: true,
    isOpenPoints: true
  },
  // contact preferences渠道绑定弹窗
  popContactBind: false,
  // contact preferences渠道绑定弹窗类型 分为‘switch’和‘change’
  popContactBindType: 'change',
  // 订阅中心绑定手机号国家区号编码
  countryCodes: [],
  areaCode: { code: '', abbr: '' },
  // 弹窗名字
  dialogName: '',
}

export default state
