<!-- eslint-disable vue/v-on-event-hyphenation -->
<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div :class="['bsc-search-box', searchClass]">
    <DefaultSearchBox 
      ref="defaultSearchBox"
      :isBigSearch="isBigSearch"
      :isRoundSearch="searchClass.isRoundSearch"
      :lang="lang"
      :siteUID="SiteUID"
      :language="language"
      :isCorrectionRule="false"
      :associationListNew="associationListNew"
      :marketCallback="marketCallback"
      :associationNewpresearchlenovo="associationNewpresearchlenovo"
      :associationNewpresearchicon="associationNewpresearchicon"
      :getTrackData="getTrackData"
      :fetchSearchTermsParams="fetchSearchTermsParams"
      @inputFocus="handleInputFocus"
      @exposeAssoc="handleExposeAssocDebounce"
      @expose_search="handleExposeSearch"
      @slide_expose_search="handleSlideExposeSearch"
      @clearCurSearchWord="handleClearCurSearchWord"
      @clearSearchTerm="handleClearSearchTerm"
    />
  </div>
</template>

<script name="BSDefaultSearchBox" setup>
import { debounce } from '@shein/common-function'
import { onMounted, ref, computed, nextTick, onBeforeMount, defineExpose } from 'vue'
import { getQueryString } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { DefaultSearchBox } from '@shein-aidc/bs-search-box-laptop'
import { formatSearchModuleAbtParam } from 'public/src/pages/common/search_words/formatSearchWordAbt.js'
import analysis from './analysis/index'
import fetchPresearchLang from './bff/language.js'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  isEnlargeSearchPro: {
    type: Boolean,
    default: false
  },
  isRoundSearch: {
    type: Boolean,
    default: false
  }
})
const { lang, SiteUID } = gbCommonInfo
const language = ref({})

const abtSearchWords = {
  correctionRule: 'type=B', //纠错词
}

const oldAbt = {
  correctionRule: 'SearchSuggestwordFeedback',
  RelatedSearchNew: 'RelatedSearchNew',
}
const abtSearchPoskeyMap = {
  PCAlltabCategory: 'PCAlltabCategory',
  SuggestUI: 'SuggestUI',
  HKTagSwitch: 'searchwordstyle',
  newStyle: 'newsearch' // 搜索框新样式：搜索框样式强化
}
// 底纹词曝光埋点用，参见需求wiki: pageId=1459831929
let exposeSearchTextArr = []

const abtSearchWordsRef = ref(abtSearchWords)

// 是否开始监听resize，重新insertAssociationAttrs
// const associationAttrResizing = ref(false)

const fetchSearchTermsParams = ref({})

const abtInfoRef = ref({
  SearchSuggestwordFeedback: '',
  PCAlltabCategory: '',
  RelatedSearchNew: '',
  searchwordstyle: null,
  SuggestUI: null,
  newsearch: null,
})

const defaultSearchBox = ref(null)

const isBigSearch = computed(() => {
  const { PCAlltabCategory } = abtInfoRef.value
  return PCAlltabCategory?.param?.Alltab_head === 'showall&newhead&Enlargesearch'
})

const searchClass = computed(() => {
  const comp = getQueryString({ key: 'comp' })
  const srcModule = getQueryString( { key: 'src_module' })

  // is server render
  if (typeof window === 'undefined') {
    return {
      'search-enlarge-pro': props.isEnlargeSearchPro,
      isRoundSearch: props.isRoundSearch,
    }
  }

  const { RelatedSearchNew, newsearch } = abtInfoRef.value 
  const isEnlargeSearchPro = RelatedSearchNew?.param?.RelatedSearchLocNew === 'top'
  const searchInputNewStyle = newsearch?.param?.Listnewsearch === 'new'

  return {
    'hidden': srcModule === 'storeHomePage' && comp === 'shop-activity',
    'search-enlarge-pro': isBigSearch.value && isEnlargeSearchPro,
    isRoundSearch: searchInputNewStyle
  }
})

const parseAbt = async () => {
  const posKeys = [...Object.values(abtSearchPoskeyMap), ...Object.values(oldAbt)]
  const [abtInfo, languageRes] = await Promise.all([getUserAbtData(), fetchPresearchLang()])
  language.value = languageRes.info?.result || {}
  Object.keys(oldAbt).forEach(_ => {
    const posKey = oldAbt[_];
    (abtInfo[posKey]?.param || abtInfo[posKey]?.p) && (abtSearchWordsRef.value[_] = formatSearchModuleAbtParam(abtInfo[posKey]) || abtInfo[posKey].param || abtInfo[posKey].p)
  })

  // sc
  posKeys.forEach((sceneStr) => {
    if (abtInfo[sceneStr] && Object.keys(abtInfo[sceneStr]).length > 0) {
      abtInfoRef.value[sceneStr] = abtInfo[sceneStr]
    }
  })
}

const handleExposeSearch = (payload) => {
  // 等等 pv 设置 pageName 避免这里曝光搜索框的时候还没有呢
  setTimeout(() => {
    analysis.sa.searchInput('expose_search', payload, { Searchboxform: 2 })
  }, 100)
}

const handleSlideExposeSearch = ({ defaultWords, index, currentWord, searchData } = {}) => {
  const pageType = window.gbCommonInfo?.ENTRY_PAGE_NAME || (window?.gbRawData?.results?.cat_info?.pageName || window?.gbRawData?.cat_info?.pageName)
  // hack code 搜索结果页
  if(['page_search'].includes(pageType)) {
    return
  }
  // 商详走这，搜索结果页走上面。
  // if(['page_goods_detail'].includes(pageType) && index > 0) {
  //   return 
  // }

  // 针对搜索框轮播上报（注意特殊case， 搜索结果页）
  if (!defaultWords[index]?.word || exposeSearchTextArr?.includes(defaultWords[index]?.word)) {
    return
  }

  analysis.sa.searchInput('expose_search', {
    currentWord: currentWord.guideObj
      ? currentWord.guideObj.keywords
      : currentWord.keywords,
    defaultWords: [searchData],
    localIndex: index,
  }, { Searchboxform: 2 })
  exposeSearchTextArr.push(defaultWords[index]?.word)

  // sessionStorage.setItem(
  //   `${lang}/exposeSearchTextArr`,
  //   JSON.stringify(exposeSearchTextArr)
  // )
}

const handleClearCurSearchWord = () => {
  analysis.sa.clickDelete()
}

const handleInputFocus = (payload = {}) => {
  const {
    activedMask,
    currentWord,
    searchData,
    associationNum,
    correctionStr,
    associationWords,
    historyWords,
    hotWords,
    hotImages,
    defaultWords,
    status,
  } = payload


  let searchMask = document.querySelector('.bsc-search-box__mask')
  if (searchMask) {
    searchMask.style.display = activedMask ? 'block' : 'none'
  }

  if (!activedMask) return

  const vm = {
    currentWord,
    placeholder: searchData,
    associationNum,
    correction: correctionStr,
    associationWords,
    historyWords,
    hotWords,
    hotImages,
    defaultWords,
    status,
    $nextTick: nextTick
  }

  analysis.doFocusAnalysis(vm) 
}

const handleExposeAssoc = (payload = {}) => {
  const {
    currentWord,
    correctionStr,
    associationWords,
  } = payload

  const vm = {
    associationWords,
    currentWord,
    correction: correctionStr,
  }

  analysis.exposeAssoc(vm)
}

const handleExposeAssocDebounce = debounce({
  func: handleExposeAssoc,
  wait: 2000,
})

const exposeFromSessionStorage = () => {
  exposeSearchTextArr = []
//   exposeSearchTextArr = JSON.parse(
//     sessionStorage.getItem(`${lang}/exposeSearchTextArr`) || '[]'
//   )
}

const getTrackData = ({ encodeKeyword, keyword, full_name, result_type, search_redir, position, originWord, tag, wordTagType, wordOriginRecord, wordExtraReport, reportExtendData } = {}) => {
  const ana_keyword = encodeKeyword
  const ana_crowdId = keyword?.crowdId || 0
  const urlTrackData = analysis.search({
    keyword: ana_keyword,
    full_name: encodeURIComponent(full_name || '') || ana_keyword,
    result_type,
    search_redir,
    data: {
      trace_id: keyword?.trace_id || wordOriginRecord?.trace_id || '-',
      word_id: keyword?.word_id || wordOriginRecord?.word_id || '-',
      position,
      type: keyword?.type,
      tag,
    },
    crowdId: ana_crowdId,
    originWord,
    wordTagType,
    wordExtraReport,
    reportExtendData
  })
  return urlTrackData
}

const injectWindow = (data) => {
  Object.keys(data).forEach((key) => {
    window[key] = data[key]
  })
}

const changeSearchDefaultWord = (payload) => {
  fetchSearchTermsParams.value = payload
}

const handleClearSearchTerm = () => {
  window.sa('send', {
    activity_name: 'click_search_clear_new',
    activity_param: {}
  })
}

onBeforeMount(()=>{
  // 业务出注册的
  window.$initAdvertisingPageSearchInputDefaultParams && (fetchSearchTermsParams.value = window.$initAdvertisingPageSearchInputDefaultParams)
})
onMounted(() => {
  injectWindow({
    $ChangeSearchDefaultWordFun: changeSearchDefaultWord,
  })
  parseAbt()

  exposeFromSessionStorage()
})

defineExpose({
  exposeFromSessionStorage
})
</script>

<style lang="less" scoped>
.bsc-search-box {
  flex-basis: 530px;
  max-width: 530px;

  &.hidden {
    display: none;
  }

  &.search-enlarge-pro {
    --bsc-search-box-max-width: 100%;
    flex-basis: 916px;
    max-width: 916px;
  }
}

</style>
